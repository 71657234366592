import * as imageConst from '../../images'

const ExplorePropel = () => {
  const url = window.location.pathname.split('/')[1]
  const maasData = [
    {
      name: 'MaaS',
      desc: 'The blockchain plugins required to establish a Metaverse environment.',
      imageUrl: imageConst.maasLogo,
      link: '/maas',
    },
    {
      name: 'Launchpool',
      desc: 'Provide Exciting rewards on your projects through Propel Pool.',
      imageUrl: imageConst.maasLogo,
      link: '/launchpool',
    },
  ]
  const daasData = [
    {
      name: 'DaaS',
      desc: 'A plug-n-play DeFi solution for new and existing projects to spin up custom DeFi smart contracts',
      imageUrl: imageConst.maasLogo,
      link: '/daas',
    },
    {
      name: 'Launchpool',
      desc: 'Provide Exciting rewards on your projects through Propel Pool.',
      imageUrl: imageConst.maasLogo,
      link: '/launchpool',
    },
  ]
  const exploreData = url === 'daas' ? maasData : daasData
  return (
    <>
      <div
        style={{
          background: '#DBDCF4',
        }}
        className="w-full "
      >
        <div className="w-full m-auto max-w-1123 px-5 py-14 sm:py-24">
          <h1
            style={{
              color: '#00144A',
            }}
            className=" font-extrabold text-32 sm:text-40"
          >
            Explore Propel’s Product Suite
          </h1>
          <div className="flex flex-wrap mt-20 justify-start items-center gap-72">
            {exploreData?.map((data, index) => {
              return (
                <div key={index}>
                  <div className="flex justify-start items-center">
                    <img
                      src={data.imageUrl}
                      className=" w-9 h-9 bg-white rounded-full"
                      alt="logo"
                    />
                    <p
                      style={{
                        color: '#00144A',
                      }}
                      className=" font-semibold text-18 ml-3"
                    >
                      {' '}
                      {data.name}{' '}
                    </p>
                  </div>
                  <p
                    style={{
                      color: 'rgba(0, 0, 0, 0.6)',
                      maxWidth: '310px',
                    }}
                    className=" mt-3 h-20 mb-8 font-normal text-base"
                  >
                    {' '}
                    {data.desc}{' '}
                  </p>
                  <a href={data.link} rel="noreferrer">
                    <p
                      style={{
                        color: '#2C7AF5',
                      }}
                      className="flex text-15 font-normal justify-start items-center"
                    >
                      Know more &#x3e;
                    </p>
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
export default ExplorePropel
