import CustomBag from './CustomBag'
import DeFiCustomSolutions from './DeFiCustomSolutions'

const Solutions = () => {
  return (
    <>
      <div className=" w-full ">
        <div className="m-auto w-full max-w-1123 px-5">
          <h1 className=" my-20 py-2 text-center font-semibold text-3xl text-white">
            Suite of{' '}
            <span
              style={{
                color: '#F9AE3E',
              }}
              className=" text-3xl font-extrabold"
            >
              Propel Custom Solutions{' '}
            </span>{' '}
            to refer
          </h1>
          <div className="relative w-full h-1770 lg:h-680">
            <div className="flex w-full flex-wrap justify-center items-start gap-6 absolute top-0">
              <DeFiCustomSolutions />
              <CustomBag />
            </div>

            <div
              style={{
                left: '50%',
                transform: 'translate(-50%, 0)',
              }}
              className="bg-white w-52 h-12 hidden rounded text-black absolute z-20 bottom-14 font-extrabold text-base lg:flex justify-center items-center"
            >
              OR
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Solutions
