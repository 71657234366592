import AvailableOn from '../available/available'
import * as imageConst from '../../images'
import { links } from '../../data/links'
import BuyPel from '../buyPel/BuyPel'

const MaasProvider = () => {
  return (
    <>
      <div
        style={{
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 100%',
          backgroundImage: `url(${imageConst.maasIllustration})`,
        }}
        className="w-full relative h-517 sm:h-860"
      >
        <div className="w-full pt-40">
          <h1 className="text-center font-bold text-40 sm:text-62 text-white">
            MaaS
          </h1>
          <p
            style={{
              color: 'rgba(255, 255, 255, 0.74)',
              maxWidth: 671,
            }}
            className=" m-auto mb-9 text-xl sm:text-2xl font-normal text-center"
          >
            One-stop Metaverse PLUGINS and INFRASTRUCTURE solution provider.
          </p>

          <div className="w-full flex-wrap gap-8 flex justify-center items-center">
            <a
              style={{
                maxWidth: 251,
              }}
              href={links.CALENDLY}
              target="_blank"
              rel="noreferrer"
              className="w-full"
            >
              <button className=" w-full sm:w-251 h-12 rounded-sm flex px-4 justify-center items-center text-white bg-purplish font-Mulish font-bold text-sm">
                Get Started Now
                <span>
                  <img
                    loading="lazy"
                    className=" w-5 h-3 ml-7"
                    src={imageConst.arrowRight}
                    alt="arrow"
                  />
                </span>
              </button>
            </a>
            <BuyPel />
          </div>
        </div>
        <div className="absolute bottom-0 w-full">
          <AvailableOn />
        </div>
      </div>
    </>
  )
}
export default MaasProvider
