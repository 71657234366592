import { useState } from 'react'
import * as imageConst from '../../images'
import { links } from '../../data/links'
import pricingPage from '../../data/pricing/pricingStats.json'

const PricingStructure = () => {
  const [isMonthly, setIsMonthly] = useState(0)
  const buttonData = ['Monthly', 'One Time']
  const pricingData = [
    {
      name: 'Investor Dashboard',
      headColor: '#00DEB6',
      backgroundColor: '#2CB477',
      offerImage: imageConst.investorOfferBg,
      tickImage: imageConst.checkGreen,
      text: (
        <p
          style={{
            color: 'rgba(255, 255, 255, 0.74)',
            width: 264,
          }}
          className=" italic mt-6 mb-14 font-normal text-base"
        >
          Design your token sale campaigns and manage tokenomics
          <span
            style={{
              color: 'rgba(255, 255, 255, 0.38)',
            }}
          >
            {' '}
            & much <br /> more with <br />
            INVESTOR DASHBOARD{' '}
          </span>
        </p>
      ),
      pricePerMonth: `${
        isMonthly === 0
          ? pricingPage.page.monthlyPerProduct
          : pricingPage.page.PerProductOneTime
      }`,
      setUpTime: pricingPage.page.standardSetupTimeFormated,
      upfrontPrevious: pricingPage.page.upfrontPerProduct,
      upfrontNow: pricingPage.page.upfrontPerProductOffer,
      inclusions: [
        'Token creation',
        'Claim cokens',
        'Manage tokenomics',
        'Vesting schedule',
        'Customizable allocation rounds',
        '100% transparent process',
      ],
    },
    {
      name: 'STAKING',
      headColor: '#F2A23A',
      backgroundColor: '#F2A23A',
      offerImage: imageConst.stakingOfferBg,
      tickImage: imageConst.checkYellow,
      text: (
        <p
          style={{
            color: 'rgba(255, 255, 255, 0.74)',
            width: 264,
          }}
          className=" italic mt-6 mb-14 font-normal text-base"
        >
          Staking module offers fixed APY, no predefined running period &
          rewards for users. Integrate single-sided
          <span
            style={{
              color: 'rgba(255, 255, 255, 0.38)',
            }}
          >
            {' '}
            staking for your token hassle-free at best price.{' '}
          </span>
        </p>
      ),
      pricePerMonth: `${
        isMonthly === 0
          ? pricingPage.page.monthlyPerProduct
          : pricingPage.page.PerProductOneTime
      }`,
      setUpTime: pricingPage.page.standardSetupTimeFormated,
      upfrontPrevious: pricingPage.page.upfrontPerProduct,
      upfrontNow: pricingPage.page.upfrontPerProductOffer,
      inclusions: [
        'Stake Token A & get Token A ',
        'Customizable unbonding period',
        'Fixed APY',
        'Indefinite running period',
        'Configurable APY',
        'Configurable claim delay ',
      ],
    },
    {
      name: 'LP STAKING',
      headColor: '#0B70E7',
      backgroundColor: '#0B70E7',
      offerImage: imageConst.offerBlue,
      tickImage: imageConst.checkBlue,
      text: (
        <p
          style={{
            color: 'rgba(255, 255, 255, 0.74)',
            width: 264,
          }}
          className=" italic mt-6 mb-14 font-normal text-base"
        >
          LP Staking module offers dynamic APY, fixed running period & fixed
          rewards for users. Increase your token's utility, integrate
          <span
            style={{
              color: 'rgba(255, 255, 255, 0.38)',
            }}
          >
            {' '}
            LP staking for your token at the lowest cost
          </span>
        </p>
      ),
      pricePerMonth: `${
        isMonthly === 0
          ? pricingPage.page.monthlyPerProduct
          : pricingPage.page.PerProductOneTime
      }`,
      setUpTime: pricingPage.page.standardSetupTimeFormated,
      upfrontPrevious: pricingPage.page.upfrontPerProduct,
      upfrontNow: pricingPage.page.upfrontPerProductOffer,
      inclusions: [
        'Stake LP Token - get Native Token',
        'Helps in increasing liquidity',
        'Fixed rewards and running period',
        'Customizable staking options',
        'Dynamic APY',
      ],
    },
  ]
  return (
    <>
      <div className=" w-full sm:mt-14 sm:mb-36">
        <div className=" w-full mb-2 max-w-1123 px-5 m-auto flex flex-col justify-center items-center">
          <h1 className=" font-semibold text-32 text-white mb-9 text-center md:text-left">
            Complex Infrastructure, Simple PRICE
          </h1>
          <div
            style={{
              background: 'rgba(255, 255, 255, 0.1)',
              border: '1px solid rgba(255, 255, 255, 0.32)',
              minWidth: 312,
            }}
            className=" flex justify-center flex-wrap items-center rounded overflow-hidden mb-28"
          >
            {buttonData.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{
                    color: `${
                      isMonthly === index
                        ? 'white'
                        : ' rgba(255, 255, 255, 0.74)'
                    }`,
                  }}
                  onClick={() => setIsMonthly(index)}
                  className={`${
                    isMonthly === index
                      ? 'bg-purplish font-bold'
                      : 'font-normal'
                  } text-xl cursor-pointer  h-16 flex flex-100 sm:flex-155 justify-center items-center`}
                >
                  {data}
                </div>
              )
            })}
          </div>
          <div className="flex flex-wrap justify-center items-start">
            {pricingData.map((data, index) => {
              return (
                <div
                  className={`${
                    index === 1 ? 'mx-12 ' : ''
                  } mb-5 sm:mb-0 w-80 sm:w-368`}
                  key={index}
                >
                  <div
                    style={{
                      height: 284,
                    }}
                    className=" bg-lightBlack m-auto sm:w-331 w-300 px-11 pt-8"
                  >
                    <p
                      style={{
                        lineHeight: '40px',
                        letterSpacing: '0.221em',
                      }}
                      className=" font-DMSans uppercase text-center font-medium text-base text-white"
                    >
                      {' '}
                      {data.name}
                    </p>
                    <div
                      style={{
                        background: `${data.headColor}`,
                        borderRadius: '0px 0px 4px 4px',
                      }}
                      className=" h-1 w-60"
                    ></div>
                    {data.text}
                  </div>
                  <div
                    style={{
                      border: `1px solid ${data.backgroundColor}`,
                      boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08)',
                    }}
                    className=" flex flex-col justify-between items-center w-full bg-black rounded-xl"
                  >
                    <div
                      style={{
                        height: 104,
                        margin: '63px 0 21px',
                        background: `${data.backgroundColor}`,
                        boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08)',
                      }}
                      className={` w-full text-white flex justify-center items-center`}
                    >
                      <p className=" font-bold text-34 lg:text-64">
                        {data.pricePerMonth}{' '}
                      </p>
                      <p
                        style={{
                          fontSize: 17,
                          marginLeft: 6,
                        }}
                        className=" font-medium"
                      >
                        {' '}
                        {isMonthly === 0 && '/month'}
                      </p>
                    </div>
                    <p className="text-white font-DMSans font-normal text-sm text-center">
                      Standard setup: {data.setUpTime}
                    </p>
                    {isMonthly === 0 && (
                      <div className=" font-DMSans px-12 w-full">
                        <p
                          style={{
                            color: 'rgba(255, 255, 255, 0.3)',
                            margin: '38px 0 14px',
                          }}
                          className=" font-bold text-xl"
                        >
                          SUBSCRIPTION
                        </p>
                        <div className="flex justify-between items-center mb-3">
                          <div className="flex justify-start items-center">
                            <p
                              style={{
                                color: 'rgba(255, 255, 255, 0.87)',
                              }}
                              className="font-normal text-base"
                            >
                              Upfront
                            </p>
                            <p
                              style={{
                                backgroundImage: `url(${data.offerImage})`,
                                backgroundSize: '100% 100%',
                                backgroundRepeat: 'no-repeat',
                                width: 81,
                                height: 20,
                                fontSize: 10,
                                paddingLeft: 6,
                              }}
                              className="flex ml-4 text-white text-center font-bold justify-start items-center"
                            >
                              NEW OFFER
                            </p>
                          </div>
                          <div className="flex">
                            <p
                              style={{
                                color: 'rgba(255, 255, 255, 0.38)',
                              }}
                              className=" font-normal text-base relative"
                            >
                              {' '}
                              {data.upfrontPrevious}
                              <span
                                style={{
                                  top: 12,
                                  background: 'rgba(255, 255, 255, 0.38)',
                                  height: 1,
                                }}
                                className=" absolute left-0 right-0 h-1 "
                              ></span>
                            </p>
                            <p className=" ml-2 font-bold text-base text-white">
                              {' '}
                              {data.upfrontNow}
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <p
                            style={{
                              color: 'rgba(255, 255, 255, 0.87)',
                            }}
                            className="font-normal text-base"
                          >
                            {isMonthly === 0 ? 'Monthly' : 'One Time'}
                          </p>
                          <p className="font-bold text-base text-white">
                            {data.pricePerMonth}
                          </p>
                        </div>
                      </div>
                    )}
                    <a href={links.CALENDLY} target="_blank" rel="noreferrer">
                      <div
                        style={{
                          background: 'rgba(140, 70, 255, 0.1)',
                          border: '1px solid #8C46FF',
                          width: 165,
                          height: 40,
                        }}
                        className=" mt-9 text-white font-normal text-15 rounded h-10 mb-7 flex justify-center items-center"
                      >
                        Get Started <span className="ml-7"> &#8594;</span>
                      </div>
                    </a>
                  </div>
                  <div
                    style={{
                      minHeight: 518,
                      padding: '28px 18px 62px',
                    }}
                    className=" sm:w-331 w-300 bg-lightBlack m-auto"
                  >
                    <h1
                      style={{
                        color: 'rgba(255, 255, 255, 0.38)',
                      }}
                      className="font-bold text-xl mb-4"
                    >
                      What’s included
                    </h1>
                    {data.inclusions.map((title, i) => {
                      return (
                        <div
                          key={i}
                          className="flex items-center justify-start mb-10"
                        >
                          <img
                            src={data.tickImage}
                            className="mr-6 w-6 h-6 flex justify-center items-center object-contain"
                            alt="tick-mark"
                          />
                          <p className="text-white font-normal text-base">
                            {title}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
export default PricingStructure
