import DeFiFeatures from '../common/components/deFiFeatures/DeFiFeatures'
import DeFiServices from '../common/components/deFiServices/DeFiServices'
import ExplorePropel from '../common/components/explorePropel/ExplorePropel'
import Faqs from '../common/components/faqs/Faqs'
import Footer from '../common/components/footer/Footer'
import GetInTouch from '../common/components/getInTouch/GetInTouch'
import DaasGetStarted from '../common/components/getStarted/DaasGetStarted'
import HeaderComponent from '../common/components/header/HeaderComponent'
import Solutions from '../common/components/solutions/Solutions'
import StickySocialLinks from '../common/components/stickySocialLinks/StickySocialLinks'
import * as imageConst from '../common/images'
const Daas = () => {
  return (
    <>
      <div
        className=" overflow-scroll w-full"
        style={{ background: '#050505' }}
      >
        <div
          style={{
            backgroundImage: `url(${imageConst.pattern})`,
            backgroundSize: '100% 100%',
            backgroundPosition: '0 -118px',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <HeaderComponent />
          <DaasGetStarted />
          <DeFiServices />
        </div>
        <DeFiFeatures />
        <StickySocialLinks />
        <Solutions />
        <GetInTouch
          title=" Get started with  "
          subTitle="Propel DeFi Solutions "
          content="Get in touch with our experts to know more and begin integrating
            innovative solutions into your DeFi project."
        />
        <Faqs />
        <ExplorePropel />
        <Footer />
      </div>
    </>
  )
}
export default Daas
