import CustomisedSolutions from '../common/components/customisedSolutions/CustomisedSolutions'
import Faqs from '../common/components/faqs/Faqs'
import Footer from '../common/components/footer/Footer'
import HeaderComponent from '../common/components/header/HeaderComponent'
import Inclusions from '../common/components/inclusions/Inclusions'
import PricingStructure from '../common/components/pricingStructure/PricingStructure'
import PricingTestimonials from '../common/components/pricingTestimonials/PricingTestimonials'
import PropelPackages from '../common/components/propelPackages/PropelPackages'
import StartPricing from '../common/components/startPricing/StartPricing'
import StickySocialLinks from '../common/components/stickySocialLinks/StickySocialLinks'

const Pricing = () => {
  return (
    <>
      <div
        className=" overflow-scroll w-full"
        style={{ background: '#050505' }}
      >
        <HeaderComponent />
        <StickySocialLinks />
        <StartPricing />
        <PricingStructure />
        <CustomisedSolutions />
        <PropelPackages isPro />
        <PropelPackages />
        <Inclusions />
        <PricingTestimonials />
        <Faqs />
        <Footer />
      </div>
    </>
  )
}
export default Pricing
