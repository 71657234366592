import * as imageConst from '../../images'
import { links } from '../../data/links'

const GetInTouch = ({
  title,
  content,
  subTitle,
}: {
  title: string
  content: string
  subTitle: string
}) => {
  return (
    <>
      <div className="w-full ">
        <div
          style={{
            background: '#272A43',
          }}
          className="w-full flex-wrap rounded-lg py-14 px-11 mb-16 sm:my-24 max-w-1123 m-auto flex justify-between items-center"
        >
          <h1 className=" font-extrabold text-32 text-white">
            {title} <br /> {subTitle}{' '}
          </h1>
          <div className="w-482">
            <p className=" font-normal text-2xl text-white mb-2 py-6 sm:py-0">
              {content}
            </p>
            <a href={links.CALENDLY} target="_blank" rel="noreferrer">
              <p
                style={{
                  color: '#FF9900',
                }}
                className="flex justify-start items-center font-semibold text-sm"
              >
                CONTACT PROPEL{' '}
                <span className=" ml-6">
                  <img
                    src={imageConst.yellowArrowRight}
                    alt="yellow-arrow-right"
                  />
                </span>{' '}
              </p>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
export default GetInTouch
