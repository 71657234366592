import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper";

interface ServiceProps {
    serviceName: string,
    partners: {
        projectName: string,
        link: string,
        imageUrl: string
    }[]
}

const PartnersService = ({ service }: { service: ServiceProps }) => {
    return (
        <>
            <div
                className='md:w-full w-5/6 m-auto rounded-md font-bold text-base md:h-67 h-7 md:text-2xl flex justify-center items-center'
                style={{
                    background: 'linear-gradient(0deg, rgba(5, 5, 5, 0.97), rgba(5, 5, 5, 0.97)), #FFFFFF',
                    color: '#A671FF'
                }}
                id={service.serviceName.split(" ")[0]}
            >
                {service.serviceName}
            </div>
            <div className='w-5/6 m-auto'>
                <Swiper
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: true,
                    }}
                    navigation={true}
                    breakpoints={{
                        300: {
                            slidesPerView: 2,
                            spaceBetween: 24,
                        },
                        640: {
                            slidesPerView: 4,
                            spaceBetween: 24,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 45,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 45,
                        },
                    }}
                    modules={[Autoplay, Navigation]}
                    className="flex w-full my-6 md:my-16 py-1.5 md:px-20 flex-wrap justify-center items-center gap-x-6 gap-y-5 md:gap-x-11 md:gap-y-8"
                >
                    {
                        service.partners?.map((partner) => (
                            <SwiperSlide
                                key={partner.projectName}
                                style={{
                                    background: 'rgba(255, 255, 255, 0.09)',
                                    borderRadius: 10,
                                    width: 172,
                                    height: 127,
                                    overflow: 'hidden'
                                }}
                                className='flex justify-center w-143 h-106 md:w-172 md:h-127 items-center relative'
                            >
                                <div className='absolute top-0 left-0 right-0 h-1'
                                    style={{
                                        background: '#A671FF',
                                    }}
                                />
                                <a href={partner.link} target='_blank' rel="noreferrer" className='p-6 flex justify-center h-106 w-full md:h-127 items-center'>
                                    <img src={partner.imageUrl} alt="company" className='h-20 object-contain' />
                                </a>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </>
    )
}
export default PartnersService;