import { useState } from 'react'
import * as ImageCont from '../../images'
import './stickyComponent.css'

const StickySocialLinks = () => {
  const [isHover, setIsHover] = useState(false)
  const data = [
    {
      id: 1,
      name: 'twitter',
      image: ImageCont.twitterSticky,
      link: 'https://twitter.com/propelxyz',
    },
    {
      id: 2,
      name: 'telegram',
      image: ImageCont.telegramSticky,
      link: 'https://t.me/propelchat',
    },
    {
      id: 4,
      name: 'medium',
      image: ImageCont.mediumSticky,
      link: 'https://propelxyz.medium.com/',
    },
  ]
  return (
    <>
      <div className=" z-99 w-10 h-28 hidden fixed right-7 top-64 sm:flex flex-col justify-between items-end">
        {data.map((data) => {
          return (
            <div
              onMouseEnter={() => {
                setIsHover(true)
              }}
              onMouseLeave={() => {
                setIsHover(false)
              }}
              onClick={() => {
                setIsHover(true)
                setIsHover(false)
              }}
              key={data.id}
              style={{
                backgroundColor: '#e0e0e080',
              }}
              className={`${
                isHover ? 'sticky_links' : ''
              }  cursor-pointer w-6 h-6 object-contain rounded-full flex justify-center items-center`}
            >
              <a rel="noopener noreferrer" href={data.link} target="_blank">
                <img
                  loading="lazy"
                  src={data.image}
                  alt={data.name}
                  className=" w-4 h-4"
                />
              </a>
            </div>
          )
        })}
      </div>
    </>
  )
}
export default StickySocialLinks
