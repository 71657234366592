import * as imageConst from '../../images'

const GameFi = () => {
  return (
    <>
      <div className="w-full flex flex-col items-center">
        <h1 className="text-center text-white font-bold text-32">GameFi</h1>
        <div
          style={{
            background: '#00FFA3',
          }}
          className=" my-4 w-16 h-2 "
        ></div>
        <p className="text-center mb-16 text-white font-normal text-2xl">
          GameFi is a superior Gaming, DeFi, and NFT integration.
        </p>
        <div className=" mt-2 h-auto sm:h-1035">
          <img
            src={imageConst.gameFi}
            className=" h-full object-contain"
            alt="gameFi"
          />
        </div>
      </div>
    </>
  )
}
export default GameFi
