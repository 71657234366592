import * as imageConst from '../../images'

const CustomBag = () => {
  const data = [
    'A custom bundle of crucial DeFi services including Investor Dashboard, LP Staking, Single-asset Staking, Cross-chain Bridge, and more.',
    'Combine fundamental smart contracts including vesting contracts, staking contracts, and so on.',
  ]
  return (
    <>
      <div
        style={{
          background:
            'radial-gradient(81.7% 195.32% at 78.93% 107.09%, #1E2442 0%, #3D4560 100%)',
          width: 553,
          minHeight: 680,
        }}
        className=" rounded-lg"
      >
        <div className="w-full pt-14 px-14 pb-11 flex flex-wrap justify-center text-center lg:text-left lg:justify-start items-center">
          <img src={imageConst.headerLogo} className=" h-9" alt="propel" />
          <p className=" text-xl text-white py-5 md:py-0 px-4 font-normal">
            CUSTOM BAG
          </p>
          <p
            style={{
              background:
                'linear-gradient(107.93deg, #FF9F0F 36.82%, #FFD600 98.03%)',
              height: 31,
              width: 61,
            }}
            className="rounded-sm flex justify-center items-center"
          >
            NEW
          </p>
        </div>
        <div
          style={{
            background:
              ' linear-gradient(90deg, #252C48 6.16%, rgba(37, 44, 72, 0) 102.17%)',
          }}
          className=" h-2 w-full relative overflow-hidden"
        >
          <div
            style={{
              background:
                'linear-gradient(97.22deg, #F9AE3E 12.59%, #FFD600 74.43%)',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
              width: 84,
            }}
            className="h-full absolute left-0 top-0 bottom-0 skew-x-12"
          ></div>
          <div
            style={{
              background:
                'linear-gradient(97.22deg, #F9AE3E 12.59%, #FFD600 74.43%)',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
            }}
            className="h-full absolute left-24 w-11 top-0 bottom-0 skew-x-12"
          ></div>
        </div>
        <div className="px-2 sm:px-14 py-9 w-full">
          <p className="font-normal text-18 text-white">
            For a smooth development of your project, you may choose an unique
            bundle that includes all of the main solutions from each suite.
            Consider the following scenario:
          </p>
          <div
            style={{
              background:
                'linear-gradient(90deg, #252C48 19.72%, rgba(37, 44, 72, 0) 87.5%)',
              opacity: 0.4,
            }}
            className="mt-7 h-2 w-full"
          ></div>
          <div>
            {data?.map((data, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-start items-start mt-6"
                >
                  <div
                    style={{
                      minWidth: 26,
                      height: 26,
                      background: '#70737B',
                    }}
                    className=" rounded-full mr-4"
                  ></div>
                  <p className="text-white font-bold text-sm"> {data} </p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
export default CustomBag
