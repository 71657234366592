import * as imageConst from '../../images'

const SmartContract = () => {
  return (
    <>
      <div className="w-full">
        <div className="w-full gap-7 flex-wrap m-auto max-w-1123 px-5 mt-14 sm:mt-28 mb-8 sm:mb-24 flex justify-between">
          <div className="flex-1 relative">
            <div
              style={{
                background: '#6572DC',
              }}
              className=" absolute h-1 w-7 -top-4 left-0"
            ></div>
            <h1 className="text-white font-extrabold text-32">
              Smart Verse Contracts
            </h1>
            <div className=" pl-8 mt-16 relative ">
              <p
                style={{
                  color: '#CCCCCC',
                }}
                className="font-normal text-2xl "
              >
                Smart contracts for verification, <br /> negotiation, resolution
                and <br />
                performance of a Metaverse <br /> environment. <br /> <br />
                <span className=" uppercase">
                  MANAGE YOUR APPLICATION AND <br /> LEAVE THE SMART CONTRACT{' '}
                  <br /> WORK ON US.
                </span>
              </p>
              <div
                style={{
                  width: 3,
                  background: '#ABB3F3',
                }}
                className=" absolute top-2 bottom-2 left-0"
              ></div>
            </div>
          </div>
          <div className=" w-64 sm:w-auto pt-16">
            <img
              src={imageConst.smartContract}
              className="sm:h-518"
              alt="smart-contract"
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default SmartContract
