import * as imageConst from '../images'

const LoadingComponent = () => {
  return (
    <>
      <div className="w-full h-screen flex justify-center items-center bg-black">
        <div className=" h-48 w-48 flex justify-center items-center rounded-full animate-spin border-4 border-dashed border-purple-300  ">
          <div className="w-full flex justify-center items-center">
            <img loading="lazy" src={imageConst.headerLogo} alt="loader" />
          </div>
        </div>
      </div>
    </>
  )
}
export default LoadingComponent
