import { useEffect, useState } from 'react'
import * as imageConst from '../../images'
import { links } from '../../data/links'

const Footer = () => {
  const [year, setYear] = useState(0)

  useEffect(() => {
    const getYearFunc = () => {
      let day = new Date().getFullYear()
      setYear(day)
    }
    getYearFunc()
  }, [])
  const footerData = [
    {
      name: 'Social',
      links: [
        {
          linkName: 'Blog',
          linkAddress: links.MEDIUM_LINK,
        },
        {
          linkName: 'Telegram',
          linkAddress: links.TELEGRAM_LINK,
        },
        {
          linkName: 'Twitter',
          linkAddress: links.TWITTER_LINK,
        },
      ],
    },
    {
      name: 'Developers',
      links: [
        {
          linkName: 'Documentation',
          linkAddress: links.DOCUMENTATION,
        },
        {
          linkName: 'Github',
          linkAddress: links.GITHUB_LINK,
        },
        {
          linkName: 'Audit',
          linkAddress: links.AUDITS,
        },
      ],
    },
    {
      name: 'Help',
      links: [
        {
          linkName: 'Support',
          linkAddress: links.SUPPORT,
        },
        {
          linkName: 'Terms',
          linkAddress: links.COOKIE_POLICY,
        },
        {
          linkName: 'Privacy',
          linkAddress: links.PRIVACY_POLICY,
        },
      ],
    },
  ]
  return (
    <>
      <div className="w-full bg-black">
        <div className=" max-w-1123 px-5 m-auto w-full">
          <div className="text-center md:text-left py-12 sm:py-24 w-full flex flex-wrap justify-center md:justify-between items-center md:items-start">
            <a
              href="/"
              className="mb-10 flex-100 lg:flex-2  lg:mb-0 flex justify-center lg:justify-start"
            >
              <div
                style={{
                  width: 123,
                  height: 57,
                }}
              >
                <img
                  loading="lazy"
                  className="  w-full h-full object-contain"
                  src={imageConst.footerLogo}
                  alt="propel-logo"
                />
              </div>
            </a>
            {footerData?.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{
                    flex: '1 1 200px',
                  }}
                  className="mb-10 md:mb-0"
                >
                  <p className=" font-bold text-18 text-white text-center">
                    {data.name}{' '}
                  </p>
                  <div className="flex flex-col justify-center md:justify-start items-center">
                    {data.links?.map((link, index) => {
                      return (
                        <a
                          key={index}
                          style={{
                            color: '#D9D9D9',
                          }}
                          className=" font-normal text-base mt-3"
                          href={link.linkAddress}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {link.linkName}
                        </a>
                      )
                    })}
                  </div>
                </div>
              )
            })}
            <div className="hidden">
              <p className=" font-bold text-18 text-white">
                Subscribe to Propel newsletter
              </p>
              <p className="text-white text-base font-normal mt-3 mb-5">
                Get the latest news and updates
              </p>
              <button
                style={{
                  color: '#232526',
                  background: '#F5F5F5',
                  borderRadius: 10,
                }}
                className=" text-18 font-normal w-80 h-16 flex justify-center items-center"
              >
                Subscribe
                <img
                  loading="lazy"
                  src={imageConst.right}
                  alt="right-arrow"
                  className=" w-6 h-6 object-contain ml-9"
                />
              </button>
            </div>
          </div>
          <div
            style={{
              borderTop: '1px solid rgba(255, 255, 255, 0.17)',
              height: 91,
            }}
            className="flex justify-center md:justify-start items-center"
          >
            <p className=" font-normal text-18 text-white">© {year} Propel</p>
          </div>
        </div>
      </div>
    </>
  )
}
export default Footer
