import * as imageConst from '../../images'

const Inclusions = () => {
  const data = [
    {
      imageUrl: imageConst.createToken,
      text: (
        <p
          style={{
            color: 'rgba(255, 255, 255, 0.74)',
          }}
          className=" text-18 font-normal"
        >
          Hosting{' '}
          <span
            style={{
              color: '#4B95ED',
            }}
            className=" font-bold"
          >
            {' '}
            costs
          </span>{' '}
        </p>
      ),
    },
    {
      imageUrl: imageConst.stakeLp,
      text: (
        <p
          style={{
            color: 'rgba(255, 255, 255, 0.74)',
          }}
          className=" text-18 font-normal"
        >
          Third-party{' '}
          <span
            style={{
              color: '#4B95ED',
            }}
            className=" font-bold"
          >
            {' '}
            services costs.
          </span>{' '}
        </p>
      ),
    },
    {
      imageUrl: imageConst.manageTokenomics,
      text: (
        <p
          style={{
            color: 'rgba(255, 255, 255, 0.74)',
          }}
          className=" text-18 font-normal"
        >
          Smart Contract{' '}
          <span
            style={{
              color: '#4B95ED',
            }}
            className=" font-bold"
          >
            {' '}
            Deployment costs.
          </span>{' '}
        </p>
      ),
    },
    {
      imageUrl: imageConst.stakeTokens,
      text: (
        <p
          style={{
            color: 'rgba(255, 255, 255, 0.74)',
          }}
          className=" text-18 font-normal"
        >
          Ether/BNB (or any of the other tokens){' '}
          <span
            style={{
              color: '#4B95ED',
            }}
            className=" font-bold"
          >
            {' '}
            Cost for live testing.
          </span>{' '}
        </p>
      ),
    },
    {
      imageUrl: imageConst.thirtyMinutes,
      text: (
        <p
          style={{
            color: 'rgba(255, 255, 255, 0.74)',
          }}
          className=" text-18 font-normal"
        >
          {' '}
          <span
            style={{
              color: '#4B95ED',
            }}
            className=" font-bold"
          >
            Any other costs{' '}
          </span>{' '}
          not directly related to Propel.{' '}
        </p>
      ),
    },
  ]
  return (
    <>
      <div className=" w-full mt-14 mb-16 bg-lightBlack py-12">
        <div className=" w-full max-w-1123 px-5 m-auto  flex justify-between items-center">
          <div className="font-DMSans">
            <p className="mb-11 text-white text-2xl font-normal">
              What’s Not Included ?
            </p>
            <div className=" flex-wrap flex justify-between items-center">
              {data.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-100 sm:flex-50 justify-start items-center mb-8 "
                  >
                    <div
                      style={{
                        background: '#FF737B',
                        minWidth: '40px',
                        minHeight: '40px',
                      }}
                      className="w-10 h-10 rounded-full  mr-3 flex justify-center items-center"
                    >
                      <img
                        src={data.imageUrl}
                        className=" w-5 h-5 object-contain"
                        alt="notIncluded-icon"
                      />
                    </div>
                    <div>{data.text}</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Inclusions
