import * as imageConst from '../../images'

const CoreMetaVerse = () => {
  const coreData = [
    'Tokenize virtual entity through ERC-721.',
    'Introduce a token for your virtual environment through ERC-721',
  ]
  return (
    <>
      <div className="w-full max-w-1123 flex-wrap lg:flex-nowrap px-5 sm:mt-28 mt-14 sm:mb-28 m-auto flex justify-center items-center">
        <div>
          <h1
            style={{
              color: '#00FFB6',
            }}
            className=" font-bold text-3xl"
          >
            Token <span className="text-white"> Standards</span>
          </h1>
          <div
            style={{
              background: '#FF9F00',
            }}
            className=" mt-14 mb-10 w-6 h-1"
          ></div>
          <p className="text-white font-normal text-18 mb-14">
            This module includes a complete, configurable, and secure <br />
            ERC-20, ERC-721 & ERC-1155 standard solution
          </p>
          <div>
            {coreData?.map((data, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-start items-center mb-4"
                >
                  <div
                    style={{
                      background:
                        'linear-gradient(180deg, #F7A050 0%, #D65757 100%)',
                      borderRadius: 0.75,
                      width: 9,
                      height: 9,
                    }}
                    className="rotate-45 mr-4"
                  ></div>
                  <p className="text-white font-normal text-lg"> {data} </p>
                </div>
              )
            })}
          </div>
        </div>
        <div>
          <img
            src={imageConst.coreMetaverse}
            style={{ height: 614 }}
            alt="core-metaverse"
            className=" object-cover"
          />
        </div>
      </div>
    </>
  )
}
export default CoreMetaVerse
