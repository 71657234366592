import CoreMetaVerse from '../common/components/coreMetaVerse/CoreMetaVerse'
import ExplorePropel from '../common/components/explorePropel/ExplorePropel'
import Faqs from '../common/components/faqs/Faqs'
import Footer from '../common/components/footer/Footer'
import GameFi from '../common/components/gameFi/GameFi'
import GetInTouch from '../common/components/getInTouch/GetInTouch'
import HeaderComponent from '../common/components/header/HeaderComponent'
import MaasProvider from '../common/components/maasProvider/MaasProvider'
import NFTUtilities from '../common/components/nftUtilities/NFTUtilities'
import SmartContract from '../common/components/smartContract/SmartContract'
import StickySocialLinks from '../common/components/stickySocialLinks/StickySocialLinks'

const Maas = () => {
  return (
    <>
      <div
        className=" overflow-scroll w-full"
        style={{ background: '#050505' }}
      >
        <HeaderComponent />
        <MaasProvider />
        <SmartContract />
        <StickySocialLinks />
        <GameFi />
        <NFTUtilities />
        <CoreMetaVerse />
        <GetInTouch
          title=" Bring your Metaverse "
          subTitle="Project to life! "
          content="Get in touch with our experts to know more and begin integrating immersive solutions into your Metaverse project."
        />
        <Faqs />
        <ExplorePropel />
        <Footer />
      </div>
    </>
  )
}
export default Maas
