import * as imageConst from '../../images'
import { links } from '../../data/links'
import pricingData from '../../data/pricing/pricingStats.json'

const StartPricing = () => {
  return (
    <>
      <div className=" w-full">
        <div className=" w-full text-center sm:text-left flex-wrap m-auto max-w-1123 px-5 flex justify-center sm:justify-between items-center">
          <div
            style={{
              width: 682,
            }}
            className=" text-white mb-4 md:mb-0"
          >
            <h1
              style={{
                lineHeight: '130%',
              }}
              className=" font-bold font-DMSans text-2xl sm:text-5xl"
            >
              Save
              <span className=" text-greenish"> HOURS </span>
              of development at
              <span className=" text-greenish">
                {' '}
                {pricingData.page.lowerCostPercentage} lower COST{' '}
              </span>{' '}
            </h1>
            <p className="font-DMSans mt-4 text-lg sm:text-2xl font-bold">
              Standard Setup Time
            </p>
            <p className="font-DMSans font-bold sm:text-140 text-40">
              {pricingData.page.standardSetupTime}
            </p>
            <p className=" mb-2 font-bold text-greenish text-lg sm:text-2xl">
              FREE setup
            </p>
            <p
              style={{
                color: 'rgba(255, 255, 255, 0.74)',
              }}
              className=" font-normal mb-16 text-base"
            >
              24/7 maintainence support
            </p>
            <div className=" flex-wrap gap-8 flex justify-center sm:justify-start items-center">
              <a
                style={{
                  maxWidth: 251,
                }}
                href={links.CALENDLY}
                target="_blank"
                rel="noreferrer"
                className="w-full"
              >
                <button className=" w-full sm:w-251 h-12 rounded-sm flex px-4 justify-center items-center text-white bg-purplish font-Mulish font-bold text-sm">
                  Get Started Now
                  <span>
                    <img
                      loading="lazy"
                      className=" w-5 h-3 ml-7"
                      src={imageConst.arrowRight}
                      alt="arrow"
                    />
                  </span>
                </button>
              </a>
            </div>
          </div>
          <div className="flex h-588 md:h-747 justify-center items-center">
            <img
              src={imageConst.pricingIllustration}
              style={{
                width: 400,
              }}
              className=" bg-pink object-contain"
              alt="pricing-illustration"
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default StartPricing
