const propelHomepage = '/'

export const PATH = {
  home: propelHomepage,
  daas: `${propelHomepage}daas`,
  maas: `${propelHomepage}maas`,
  pricing: `${propelHomepage}pricing`,
  launchPool: `${propelHomepage}launchpool`,
  partners: `${propelHomepage}partners`,
}
