import * as imageConst from '../../images'

const DeFiServices = () => {
  const serviceData = [
    {
      name: 'Save time ',
      desc: 'Rapid time-to-deployment',
      imageUrl: imageConst.clock,
      backgroundColor:
        'linear-gradient(90.92deg, #5D40A3 1.47%, #193761 111.95%)',
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    {
      name: 'Save effort  ',
      desc: 'Manage application, not infrastructure.',
      imageUrl: imageConst.setting,
      backgroundColor:
        'linear-gradient(90.92deg, #A34067 1.47%, #581961 111.95%)',
      boxShadow:
        '0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 4px rgba(0, 0, 0, 0.25)',
    },
    {
      name: 'Save money ',
      desc: 'Reduce cost of ownership',
      imageUrl: imageConst.rupay,
      backgroundColor:
        'linear-gradient(90.92deg, #40A372 1.47%, #193061 111.95%)',
      boxShadow:
        ' 0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  ]
  return (
    <>
      <div
        style={{
          backdropFilter: 'blur(20px)',
          background:
            'linear-gradient(270deg, #000000 20.66%, rgba(28, 28, 28, 0.0001) 65.23%), rgba(255, 255, 255, 0.03)',
        }}
        className="w-full"
      >
        <div className="w-full py-9 sm:pt-28 sm:pb-28 max-w-1123 px-5 m-auto flex justify-center items-center flex-col">
          <h1 className=" font-extrabold text-26 sm:text-34 text-white text-center sm:text-left">
            DaaS - DeFi as Service{' '}
          </h1>
          <p className=" font-normal text-center sm:text-left text-base text-white mt-6 mb-16">
            Solutions that’ll help you save time, effort & money on your dApps
          </p>
          <div className="flex flex-wrap justify-center items-center">
            {serviceData?.map((data, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    index === 1
                      ? 'items-center'
                      : index === 2
                      ? 'items-end'
                      : 'items-start'
                  } flex mb-5 lg:mb-0 flex-col`}
                >
                  <div className="flex justify-center items-center">
                    <div
                      style={{
                        height: 0.8,
                      }}
                      className={`${
                        index === 0 ? 'hidden' : 'hidden lg:block'
                      } w-32 bg-white`}
                    ></div>
                    <div className=" w-24 h-24 mx-14 flex justify-center items-center border border-white rounded-full">
                      <div
                        style={{
                          width: 73,
                          height: 73,
                          background: data.backgroundColor,
                          boxShadow: data.boxShadow,
                        }}
                        className="rounded-full flex justify-center items-center"
                      >
                        <img
                          src={data.imageUrl}
                          className="w-8 h-8"
                          alt="service"
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        height: 0.8,
                      }}
                      className={`${
                        index === 2 ? 'hidden' : 'hidden lg:block'
                      } w-32 bg-white`}
                    ></div>
                  </div>
                  <div>
                    <p className="text-center m-auto mt-5 mb-1 font-black text-2xl text-white">
                      {' '}
                      {data.name}{' '}
                    </p>
                    <p className="text-center font-normal text-base text-white">
                      {' '}
                      {data.desc}{' '}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
export default DeFiServices
