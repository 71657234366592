import Footer from '../common/components/footer/Footer'
import HeaderComponent from '../common/components/header/HeaderComponent'
import PartnersService from '../common/components/partnersService/PartnersService'
import StickySocialLinks from '../common/components/stickySocialLinks/StickySocialLinks'
import * as imageConst from '../common/images'
import partnersServices from '../common/data/partners/partners.json'
import { useState } from 'react'

const Partners = () => {
  const [serviceActive, setServiceActive] = useState('DeFi');
  console.log();

  return (
    <>
      <div
        className=" overflow-scroll w-full"
        style={{ background: '#050505' }}
      >
        <HeaderComponent />
        <StickySocialLinks />
        <div className='w-full'>
          <div className='w-full flex items-center md:h-518 h-80 bg-cover xl:bg-1'
            style={{
              backgroundImage: `url(${window.innerWidth < 520 ? imageConst.partnersBg2 : imageConst.partnersBg})`,
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className='flex items-center justify-start flex-wrap max-w-1123 m-auto gap-5 w-5/6'>
              <h1 className='text-white font-bold text-xl md:text-5xl'>The Propel’s Partner Ecosystem</h1>
              <img
                src={imageConst.partnersPel} alt="pel"
                className='object-contain md:h-133 md:w-118 w-12 h-12'
              />
            </div>
          </div>
          <div className='w-full m-auto max-w-1123'>
            <div className='w-full m-auto'>
              <div className='md:w-full w-5/6 m-auto'>
                <h2 className='font-bold text-xl md:text-40 text-white mt-6 md:mt-20 mb-3 text-center'>Propel Partners</h2>
                <p
                  style={{
                    color: 'rgba(255, 255, 255, 0.74)'
                  }}
                  className='md:text-2xl text-base font-normal text-center mb-7 md:mb-14'>Developing & Growing with partners is the building block of Propel ecosystem. </p>
                <div className='mt-0.5 mb-7 md:mb-14 h-1 w-8 rounded-lg m-auto'
                  style={{
                    background: '#C5974B'
                  }}
                />
              </div>
              <div className='w-full mb-7 overflow-x-auto'
                style={{
                  borderBottom: '1px solid #424242',
                }}
              >
                <div className='flex px-7 md:px-0 md:min-w-990 min-w-740 items-center justify-start md:justify-center m-auto gap-x-8 pb-4'>
                  {
                    partnersServices?.map((serviceType) => (
                      <a
                        rel="noopener noreferrer"
                        key={serviceType.serviceName}
                        href={`#${serviceType.serviceName.split(" ")[0]}`}
                        className={`font-Mulish relative font-normal text-base md:text-2xl ${serviceType.serviceName === serviceActive ? 'text-purplish' : 'text-white'}`}
                        onClick={() => setServiceActive(serviceType.serviceName)}
                      >
                        {serviceType.serviceName}
                        {serviceType.serviceName === serviceActive && <div className="absolute -left-3 h-1 bg-purplish -right-3 -bottom-4" />}
                      </a>
                    ))
                  }
                </div>
              </div>
              <div className='w-full'>
                {
                  partnersServices?.map((service) => <PartnersService service={service} key={service.serviceName} />
                  )
                }
              </div>
              <div className='md:w-full w-5/6 m-auto text-base font-normal text-white md:text-26 my-9 text-center'>
                <p>Let's grow together.
                  Contact us to form a partnership.</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}
export default Partners
