import * as imageConst from '../../images'
import packageData from '../../data/pricing/pricing.json'
const PropelPackages = ({ isPro }: { isPro?: boolean }) => {
  const renderData = isPro ? packageData.propelPro : packageData.propelSuit

  const data = [
    {
      name: 'Investor Dashboard',
      content: 'Design your token sale campaigns and manage tokenomics',
    },
    {
      name: 'Staking',
      content: 'Start with our audited staking smart contracts',
    },
    {
      name: 'LP Staking',
      content: 'Start with our audited staking smart contracts',
    },
  ]
  return (
    <>
      <div className=" w-full mb-14 lg:mb-28 pb-2 ">
        <div className="w-full max-w-1123 sm:px-5 m-auto">
          <div className="w-full">
            <div className="w-full h-36 bg-lightBlack flex justify-start items-center px-5 sm:px-20 uppercase font-bold text-26 sm:text-32 text-white">
              <p> {renderData.heading} </p>
              <div
                style={{
                  background: '#00DEB6',
                  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08)',
                  height: 10,
                  width: 645,
                }}
                className=" ml-14 rounded-xl"
              ></div>
            </div>
            <div className=" w-full flex flex-wrap justify-center lg:justify-start items-center py-5">
              <div className="flex justify-center items-start sm:items-center text-white lg:ml-20 mb-5 sm:mb-0">
                <p className="font-bold text-40 w-32 sm:w-auto sm:text-100">
                  {renderData.monthlyPrice}
                </p>
                <div className=" ml-5">
                  <p className=" sm:text-2xl font-bold text-lg">/ month</p>
                  <p className=" font-normal text-base">
                    Any {renderData.noOfProducts} Products
                  </p>
                </div>
              </div>
              <div className="flex justify-center items-start sm:items-center text-white lg:ml-20">
                <p className="font-bold text-40 w-32 sm:w-auto sm:text-100">
                  {renderData.oneTime}
                </p>
                <div className=" ml-5">
                  <p className=" sm:text-2xl font-bold text-lg">/ one time</p>
                  <p className=" font-normal text-base">
                    Any {renderData.noOfProducts} Products
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full bg-lightBlack flex flex-wrap justify-center items-center py-10">
              <div className=" flex flex-100 justify-center lg:justify-start lg:flex-693 flex-wrap">
                {data.map((d, index) => {
                  return (
                    <div
                      key={index}
                      className="flex gap-5 justify-start md:justify-center lg:justify-start md:w-full lg:w-auto items-start mb-8 lg:ml-16"
                    >
                      <div
                        style={{
                          background: '#4B96F7',
                        }}
                        className=" w-10 h-10 rounded-full"
                      ></div>
                      <div>
                        <p className=" mb-4 text-xl font-bold text-white">
                          {d.name}
                        </p>
                        <p
                          style={{
                            color: 'rgba(255, 255, 255, 0.74)',
                            width: 212,
                          }}
                          className=" font-normal text-base"
                        >
                          {d.content}
                        </p>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div
                style={{
                  flex: '1 1 400px',
                  borderLeft: '2px solid rgba(255, 255, 255, 0.05)',
                }}
                className=" pb-5 mt-7 lg:mt-0 lg:pl-9"
              >
                <div
                  style={{
                    width: 277,
                  }}
                  className="m-auto"
                >
                  <div className="flex justify-between items-center w-full">
                    <p className="text-white font-bold text-xl">SUBSCRIPTION</p>
                    <p
                      style={{
                        backgroundImage: `url(${imageConst.investorOfferBg})`,
                        backgroundSize: '100% 100%',
                        backgroundRepeat: 'no-repeat',
                        width: 81,
                        height: 20,
                        fontSize: 10,
                        paddingLeft: 6,
                      }}
                      className="flex ml-4 text-white text-center font-bold justify-start items-center"
                    >
                      NEW OFFER
                    </p>
                  </div>
                  <div className="flex mt-5 justify-between items-center w-full">
                    <p
                      style={{
                        color: 'rgba(255, 255, 255, 0.87)',
                      }}
                      className="font-normal text-base"
                    >
                      Upfront
                    </p>
                    <p
                      style={{
                        color: 'rgba(255, 255, 255, 0.38)',
                      }}
                      className=" font-normal text-base relative"
                    >
                      {' '}
                      {renderData.upfront}
                      <span
                        style={{
                          top: 12,
                          background: 'rgba(255, 255, 255, 0.38)',
                          height: 1,
                        }}
                        className=" absolute left-0 right-0 h-1 "
                      ></span>
                    </p>
                  </div>
                  <div className="flex justify-between mt-5 items-center w-full">
                    <p
                      style={{
                        color: 'rgba(255, 255, 255, 0.87)',
                      }}
                      className="font-normal text-base"
                    >
                      Monthly
                    </p>
                    <p className="text-base font-bold text-white">
                      {' '}
                      {renderData.monthlyPrice}{' '}
                    </p>
                  </div>
                  <div
                    style={{
                      height: 2,
                      margin: '30px 0 0 0',
                      background: 'rgba(255, 255, 255, 0.05)',
                    }}
                    className="w-full"
                  ></div>
                  <div
                    style={{
                      margin: '30px 0 20px',
                    }}
                    className="flex justify-between items-center w-full"
                  >
                    <p className="text-white font-bold text-xl">ONE TIME</p>
                  </div>
                  <div className="flex justify-between items-center w-full pb-6">
                    <p
                      style={{
                        color: 'rgba(255, 255, 255, 0.87)',
                      }}
                      className="font-normal text-base"
                    >
                      Total
                    </p>
                    <p className="text-base font-bold text-white">
                      {' '}
                      {renderData.oneTime}{' '}
                    </p>
                  </div>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default PropelPackages
