import { links } from '../../data/links'
import * as imageConst from '../../images'
import BuyPel from '../buyPel/BuyPel'
//@ts-expect-error
import whitePaper from '../../whitePaper/PropelWhitepaper.pdf'

const DaasGetStarted = () => {
  return (
    <>
      <div className="w-full px-5 flex justify-center items-center">
        <div className="w-full max-w-1156 flex flex-col justify-start items-center sm:pb-270 sm:pt-104">
          <div className="mb-6 text-center text-white text-3xl sm:text-5xl font-bold">
            <h1>
              No-configuration in-house suite of protocols, and smart contracts
              for building a DeFi ecosystem.
            </h1>
          </div>
          <div className=" sm:leading-10 text-center  text-white font-normal text-xl">
            <p>Delight your users by delivering a seamless DeFi experience.</p>
          </div>
          <a href={whitePaper} target="_blank" rel="noreferrer">
            <button className=" my-6 w-full sm:w-251 h-12 rounded-sm flex px-4 justify-center items-center text-purplish font-Mulish font-bold text-base">
              View Whitepaper
            </button>
          </a>
          <div className=" flex-wrap gap-1 mt-2 flex justify-center items-center">
            <div className=" flex-wrap gap-8 flex justify-center items-center">
              <a
                style={{
                  maxWidth: 251,
                }}
                href={links.CALENDLY}
                target="_blank"
                rel="noreferrer"
                className="w-full"
              >
                <button className=" w-full sm:w-251 h-12 rounded-sm flex px-4 justify-center items-center text-white bg-purplish font-Mulish font-bold text-sm">
                  Get Started Now
                  <span>
                    <img
                      loading="lazy"
                      className=" w-5 h-3 ml-7"
                      src={imageConst.arrowRight}
                      alt="arrow"
                    />
                  </span>
                </button>
              </a>
              <BuyPel />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default DaasGetStarted
