import { links } from '../../data/links'

const HeaderModal = ({
  linkData,
  setModal,
  modal,
}: {
  linkData: {
    name: string
    link: string
    target: boolean
  }[]
  setModal: React.Dispatch<React.SetStateAction<boolean>>
  modal: boolean
}) => {
  const url = window.location.pathname.split('/')[1]
  return (
    <>
      <div
        style={{
          zIndex: '99999999999999',
        }}
        className="w-full h-screen fixed top-0 right-0 left-0 z-40 bg-black flex flex-col"
      >
        <div className="flex w-full px-5 justify-end items-center h-20 mb-5">
          <div
            className=" cursor-pointer text-white font-bold text-2xl"
            onClick={() => setModal(!modal)}
          >
            {' '}
            X{' '}
          </div>
        </div>
        <div className="flex flex-col justify-start items-center text-center text-white font-semibold text-18">
          {linkData?.map((data, i) => {
            return (
              <a
                key={i}
                href={data.link}
                target={`${data.target ? '_blank' : '_self'}`}
                className={` no-underline mb-8 ${
                  data.name.toLowerCase() === url
                    ? 'text-purplish'
                    : 'text-white'
                } font-Mulish relative font-normal text-15 `}
              >
                {' '}
                {data.name}{' '}
                {data.name.toLowerCase() === url && (
                  <div className="absolute -left-3 h-1 bg-purplish -right-3 -bottom-3"></div>
                )}
              </a>
            )
          })}
        </div>
        <div className="flex gap-3 justify-center items-center">
          <a href={links.LAUNCH_APP} target="_blank" rel="noreferrer">
            <button className=" w-28 h-10 rounded-sm flex justify-center items-center text-white bg-purplish font-Mulish font-bold text-sm">
              Launch App
            </button>
          </a>
        </div>
      </div>
    </>
  )
}
export default HeaderModal
