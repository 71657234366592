import * as imageConst from '../../images'
import { links } from '../../data/links'
import './buyPel.css'
const BuyPel = ({ header }: { header?: boolean }) => {
  return (
    <>
      <button
        style={{
          background: `${!header ? '#2E1D43' : '#2E1D43'}`,
          maxWidth: 251,
        }}
        className={`pel_buy border-purplish relative border px-3 w-full sm:w-251 ${
          !header ? 'h-12' : 'h-10'
        } z-40 rounded-sm flex justify-between items-center text-white font-Mulish font-bold text-sm`}
      >
        <div className="flex justify-start items-center">
          <img loading="lazy" src={imageConst.kucoin} alt="kucoin" />
          <img loading="lazy" src={imageConst.pancakeSwap} alt="pancakeSwap" />
          <img loading="lazy" src={imageConst.monkeyApe} alt="monkeyApe" />
        </div>
        <div className="text-white font-normal text-base flex justify-between items-center font-Sen ">
          Buy $PEL
        </div>
        <div>
          <img
            loading="lazy"
            className=" w-3 h-3 ml-4"
            src={imageConst.arrowDown}
            alt="arrow"
          />
        </div>
        <div
          style={{
            top: `${!header ? '47px' : '39px'}`,
            border: '1px solid rgba(255, 255, 255, 0.05)',
          }}
          className="pel_buy_bottom bg-black flex flex-col justify-center items-center z-99 right-0 border-2 absolute w-full left-0"
        >
          <div
            style={{
              borderBottom: '1px solid rgba(236,241,240,.05)',
            }}
            className="flex w-full justify-center items-center bg-black"
          >
            <a href={links.KUCOIN_BUY} target="_blank" rel="noreferrer">
              <div className="main_buy_pel_inner  w-full hover:bg-lightGrey rounded-sm h-11 px-4 my-3 flex justify-between items-center">
                <div>
                  <img
                    src={imageConst.kucoinBuy}
                    className=" h-5 object-contain"
                    alt="buy-platform"
                  />
                </div>
                <div
                  style={{
                    background: '#C6C6C6',
                    color: '#1D1429',
                    fontSize: 10,
                  }}
                  className=" mx-3 font-semibold w-14 h-5 flex justify-center items-center rounded"
                >
                  BUY PEL
                </div>
                <div
                  style={{
                    height: 22,
                    minWidth: 22,
                    width: 22,
                  }}
                  className="rounded-full w-6 h-4 bg-white opacity-5 buy_pel_hover flex justify-center items-center"
                >
                  <img src={imageConst.buyPelArrowBlack} alt="buy-arrow" />
                </div>
              </div>
            </a>
          </div>
          <div className="w-full flex justify-center items-center">
            <a href={links.APESWAP_BUY} target="_blank" rel="noreferrer">
              <div className="main_buy_pel_inner w-full hover:bg-lightGrey rounded-sm h-11 px-4 my-3 flex justify-between items-center">
                <div>
                  <img
                    src={imageConst.apeSwapBuy}
                    className=" h-5 object-contain"
                    alt="buy-platform"
                  />
                </div>
                <div
                  style={{
                    background: '#C6C6C6',
                    color: '#1D1429',
                    fontSize: 10,
                  }}
                  className="mx-3 font-semibold w-14 h-5 flex justify-center items-center rounded"
                >
                  BUY PEL
                </div>
                <div
                  style={{
                    height: 22,
                    minWidth: 22,
                    width: 22,
                  }}
                  className="rounded-full w-6 h-4 bg-white opacity-5 buy_pel_hover flex justify-center items-center"
                >
                  <img src={imageConst.buyPelArrowBlack} alt="buy-arrow" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </button>
    </>
  )
}
export default BuyPel
