import { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LoadingComponent from './common/loaders/LoadingComponent'
import { PATH } from './common/routes/Routes'
import Daas from './modules/Daas'
import LaunchPool from './modules/LaunchPool'
import Maas from './modules/Maas'
import Partners from './modules/Partners'
import Pricing from './modules/Pricing'

const Home = lazy(() => import('./modules/Home'))

const App = () => (
  <Router>
    <Suspense fallback={<LoadingComponent />}>
      <Routes>
        <Route path={PATH.home} element={<Home />} />
        <Route path={PATH.daas} element={<Daas />} />
        <Route path={PATH.maas} element={<Maas />} />
        <Route path={PATH.pricing} element={<Pricing />} />
        <Route path={PATH.launchPool} element={<LaunchPool />} />
        <Route path={PATH.partners} element={<Partners />} />
      </Routes>
    </Suspense>
  </Router>
)

export default App
