export const links = {
  TWITTER_LINK: 'https://twitter.com/propelxyz',
  GITHUB_LINK: 'https://github.com/propelxyz',
  TELEGRAM_LINK: 'https://t.me/propelchat',
  MEDIUM_LINK: 'https://propelxyz.medium.com/',
  HOME_LINK: '/',
  DAAS: '/daas',
  MAAS: '/maas',
  PRICING: '/pricing',
  PARTNERS: '/partners',
  LAUNCH_APP: 'https://app.propel.xyz/',
  CALENDLY: 'https://propel.xyz/rajat',
  AUDITS: 'https://github.com/PropelXYZ/audits',
  KUCOIN_BUY: 'https://www.kucoin.com/trade/PEL-USDT',
  APESWAP_BUY:
    'https://apeswap.finance/swap?inputCurrency=ETH&outputCurrency=0xA75E7928d3de682e3F44dA60C26F33117c4E6C5c',
  GITBOOK: 'https://docs.propel.xyz/',
  SALES: 'mailto:sales@propel.xyz',
  SUPPORT: 'mailto:support@propel.xyz',
  PRIVACY_POLICY:
    'https://app.gitbook.com/s/gFhpAcXh5NWSDu5l0Mtk/privacy-policy',
  COOKIE_POLICY: 'https://app.gitbook.com/s/gFhpAcXh5NWSDu5l0Mtk/cookie-policy',
  DOCUMENTATION: 'https://docs.propel.xyz',
}
