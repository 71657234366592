import * as imageConst from '../../images'

const DeFiFeatures = () => {
  const featureData = [
    {
      mainImage: imageConst.scaleIllustration,
      headImage: imageConst.circles,
      head: 'Easy to Scale',
      title: 'Design your token sale campaigns and manage tokenomics',
      list: [
        'Select From Variety Of Token Distribution Rounds e.g. Seed, Private, Public, Team, Marketing etc ',
        'Manage Token Sale Rounds',
        'Keep Track of  Token Allocation',
      ],
    },
    {
      mainImage: imageConst.timeIllustration,
      headImage: imageConst.circles,
      head: 'Save time and cost',
      title: 'Set up bridges across multiple supported ecosystem',
      list: [
        'Eliminate The Need To Go Via Mutiple Exchanges',
        'Unites Mutiple Blockchains Through Secure Interactions',
      ],
    },
    {
      mainImage: imageConst.visibilityIllustration,
      headImage: imageConst.liquidityGain,
      head: 'Gain Liquidity & visibility',
      title: 'Single-asset staking and LP staking from multiple popular AMM’s ',
      list: [
        'Focus on Staking rather than Trading ',
        'All-in-one staking dashboard that includes farming period, APY, rewards, staked amount, and much more',
        'Both Single-sided and LP Tokens Staking module',
      ],
    },
  ]
  return (
    <>
      <div className="w-full">
        <div className="w-full m-auto max-w-1123 px-5">
          {featureData?.map((data, index) => {
            return (
              <div
                style={{
                  padding: '74px 0',
                }}
                key={index}
                className={` ${
                  index === 1 ? 'flex-row-reverse' : ''
                } flex flex-wrap justify-center items-center gap-20`}
              >
                <div
                  style={{
                    width: 502,
                  }}
                >
                  <p className="flex justify-start mb-1 items-center text-white font-bold text-lg md:text-xl">
                    <span>
                      <img
                        className=" w-6 h-6 mr-3"
                        src={data.headImage}
                        alt="title-logo"
                      />
                    </span>
                    {data.head}{' '}
                  </p>
                  <h2 className="font-bold text-xl md:text-3xl text-white mb-5">
                    {' '}
                    {data.title}{' '}
                  </h2>
                  {data.list?.map((data, i) => {
                    return (
                      <div
                        key={i}
                        className="flex justify-start items-start mb-6"
                      >
                        <img
                          src={imageConst.selectedClick}
                          className="w-4 mt-1 h-4 mr-5"
                          alt="right-symbol"
                        />
                        <p className="text-white font-normal text-sm">
                          {' '}
                          {data}{' '}
                        </p>
                      </div>
                    )
                  })}
                </div>
                <div
                  style={{
                    width: 589,
                    height: 'auto',
                  }}
                >
                  <img
                    src={data.mainImage}
                    className="w-full h-full object-contain"
                    alt=""
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
export default DeFiFeatures
