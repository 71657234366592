import { useState } from 'react'
import faqData from '../../data/faq.json'
const Faqs = () => {
  const [showIndex, setShowIndex] = useState<number>()
  const [showBox, setShowBox] = useState(false)
  const url = window.location.pathname.split('/')[1]
  let renderData =
    url === 'pricing'
      ? faqData.pricingFaq
      : url === 'maas'
      ? faqData.maasFaq
      : faqData.daasFaq
  return (
    <>
      <div className="w-full pb-16">
        <div className="w-full max-w-1123 px-5 m-auto">
          <h1 className="text-white font-extrabold text-40 mb-6 sm:mb-11">
            FAQs
          </h1>
          {renderData?.map((data, index) => {
            return (
              <div
                key={index}
                style={{
                  borderTop: ` ${
                    index === 0 ? '' : '0.5px solid rgba(255, 255, 255, 0.28)'
                  } `,
                }}
                className=" cursor-pointer px-4"
              >
                <p
                  className="py-7 font-bold text-white flex justify-between items-center w-full text-base"
                  onClick={() => {
                    setShowIndex(index)
                    setShowBox(showIndex !== index ? true : !showBox)
                  }}
                >
                  {data.question}
                  <span className=" ml-7">
                    {' '}
                    {showIndex === index && showBox ? '-' : '+'}{' '}
                  </span>{' '}
                </p>
                {showIndex === index && showBox && (
                  <p
                    style={{
                      color: 'rgba(220, 220, 220, 0.6)',
                    }}
                    className=" mb-14 font-normal text-base"
                  >
                    {data.answer}
                  </p>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
export default Faqs
