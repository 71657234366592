import * as imageConst from '../../images'
const DeFiCustomSolutions = () => {
  const data = [
    'Analyse your business needs',
    'Gather required data',
    'Consider budget and timeframe',
    'Create the final product.',
  ]
  return (
    <>
      <div
        style={{
          background:
            'radial-gradient(169.91% 279.33% at 90.8% 169.91%, #3C3A40 0%, #432B74 100%)',
          minHeight: 680,
          width: 553,
        }}
        className=" rounded-lg"
      >
        <div className="w-full pt-14 px-14 pb-11 flex-wrap flex justify-center text-center lg:text-left lg:justify-start items-center">
          <img src={imageConst.headerLogo} className=" h-9" alt="propel" />
          <p className="text-xl text-white pt-5 md:pt-0 px-4 font-normal">
            CUSTOM SOLUTIONS
          </p>
        </div>
        <div
          style={{
            background:
              ' linear-gradient(90deg, #8F1BEA 12.5%, rgba(143, 27, 234, 0) 98.37%)',
          }}
          className=" h-2 w-full relative overflow-hidden"
        >
          <div
            style={{
              background:
                'linear-gradient(97.22deg, #F9AE3E 12.59%, #FFD600 74.43%)',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
              width: 84,
            }}
            className="h-full absolute left-0 top-0 bottom-0 skew-x-12"
          ></div>
          <div
            style={{
              background:
                'linear-gradient(97.22deg, #F9AE3E 12.59%, #FFD600 74.43%)',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.15)',
            }}
            className="h-full absolute left-24 w-11 top-0 bottom-0 skew-x-12"
          ></div>
        </div>
        <div className="px-2 sm:px-14 py-9 w-full">
          <p className="font-normal text-18 text-white">
            Because each project is unique, Propel prioritises the creation of
            customised solutions. Each and every module in each suite may be
            customised. We use the following steps to prototype the best
            solution for you:
          </p>
          <div
            style={{
              background:
                'linear-gradient(90deg, #9B1BEA 13.13%, rgba(118, 27, 234, 0) 87.5%)',
              opacity: 0.4,
            }}
            className="mt-6 h-2 w-full"
          ></div>
          <div>
            {data?.map((data, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-start items-start mt-6"
                >
                  <div
                    style={{
                      width: 26,
                      height: 26,
                      background: '#3987F0',
                    }}
                    className=" rounded-full mr-4"
                  ></div>
                  <p className="text-white font-bold text-sm"> {data} </p>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
export default DeFiCustomSolutions
