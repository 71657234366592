import { useState } from 'react'
import * as imageConst from '../../images'

const NFTUtilities = () => {
  const [showIndex, setShowIndex] = useState<number>(0)
  const [showBox, setShowBox] = useState(true)
  const nftData = [
    {
      name: 'Renting NFT (re-NFT)',
      innerList: [
        {
          title: 'Lenders:',
          content:
            'Lenders can submit the NFTs they want to rent out (to earn passive income)',
        },
        {
          title: 'Borrowers:',
          content:
            " Borrowers can pay a rental fee plus a collateral amount equal to the NFT's purchase price",
        },
      ],
    },
    {
      name: 'Fractional NFT (f-NFT)',
      innerList: [
        {
          title: '',
          content:
            'The original NFT is an indivisible ERC-721 token. The fractionalised version, however, is ERC-20 tokens, which are fungible — they can be exchanged for one another since they all are a part of the same NFT.',
        },
      ],
    },
    {
      name: 'Staking NFT (s-NFT)',
      innerList: [
        {
          title: '',
          content:
            'Stake NFT, get tokens or Stake tokens, get NFT. This module by Propel offers NFT staking services.',
        },
      ],
    },
  ]
  return (
    <>
      <div className="w-full mt-32 max-w-1123 px-5 m-auto flex flex-col items-center">
        <h1 className="text-white text-center font-bold text-3xl">
          NFT Utilities
        </h1>
        <div
          style={{
            background: '#FF9F00',
          }}
          className=" w-6 h-1 my-7"
        ></div>
        <p className="text-white text-center font-normal text-2xl mb-14">
          An introduction to a completely new notion of NFT services.
        </p>
        <div className="flex flex-wrap lg:flex-nowrap justify-between items-start ">
          <img
            src={imageConst.nft}
            alt="nft"
            className=" object-contain flex-1 sm:h-588"
          />
          <div
            style={{
              background: ' #0D0D0D',
              border: ' 1px solid #2C2C2C',
              boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.3)',
              flex: '1 1 474px',
            }}
            className="mt-5 flex-1 rounded-lg "
          >
            {nftData?.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{
                    borderTop: `${index === 0 ? '' : '1px solid #222222'}`,
                  }}
                  className="relative w-full "
                >
                  {showIndex === index && showBox && (
                    <div
                      style={{
                        background: '#8256FF',
                        width: 5,
                      }}
                      className=" absolute top-0 bottom-0 left-0"
                    ></div>
                  )}
                  <div
                    onClick={() => {
                      setShowIndex(index)
                      setShowBox(showIndex !== index ? true : !showBox)
                    }}
                    className="flex cursor-pointer justify-between items-center h-16 w-full pl-9"
                  >
                    <div className="flex items-center">
                      <img
                        src={imageConst.circles}
                        className=" w-5 h-5 object-contain mr-4"
                        alt="green-background"
                      />
                      <p className=" text-18 text-white font-bold">
                        {' '}
                        {data.name}{' '}
                      </p>
                    </div>
                    {showIndex === index && showBox && (
                      <div
                        style={{
                          maxWidth: 204,
                          height: 1,
                          background: '#222222',
                        }}
                      ></div>
                    )}
                  </div>
                  {showIndex === index && showBox && (
                    <div className=" w-full pb-14">
                      {data.innerList?.map((data, i) => {
                        return (
                          <div
                            key={i}
                            className="flex mt-3 pl-9 justify-start items-start"
                          >
                            <img
                              src={imageConst.selectedClick}
                              className="mt-1 mr-3 w-4 h-4 object-contain"
                              alt="selected"
                            />
                            <p
                              style={{
                                color: 'rgba(255, 255, 255, 0.7)',
                                maxWidth: 331,
                              }}
                              className="font-normal text-base"
                            >
                              <span className="text-white font-bold">
                                {data.title}{' '}
                              </span>
                              {data.content}
                            </p>
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
export default NFTUtilities
