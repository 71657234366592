import * as imageConst from '../../images'

const PricingTestimonials = () => {
  // just for testing
  const data = [
    'mm',
    'kk',
    'll',
    'jj',
    'ii',
    'kk',
    'mm',
    'kk',
    'll',
    'jj',
    'ii',
    'kk',
    'mm',
    'kk',
    'll',
    'jj',
    'ii',
    'kk',
    'mm',
    'kk',
    'll',
    'jj',
    'ii',
    'kk',
  ]
  // testing data ends
  return (
    <>
      <div
        style={{
          padding: '185px 0 181px',
        }}
        className="w-full hidden overflow-hidden"
      >
        <div className="w-full overflow-scroll flex justify-start items-center">
          {data.map((data, i) => {
            return (
              <div
                key={i}
                style={{
                  minWidth: 305,
                  height: 402,
                  background: 'rgba(255, 255, 255, 0.06)',
                  backgroundImage: `url(${imageConst.bgColon})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '-4px -6px',
                }}
                className=" mx-6 rounded px-6 py-8 flex flex-col justify-between items-center"
              >
                <div className=" px-4">
                  <p
                    style={{
                      color: 'rgba(255, 255, 255, 0.8)',
                    }}
                    className="text-base font-normal "
                  >
                    Using your own infrastructre for blockchain creates hassles.
                    Propel DeFi Infrastructure solutions meets all our
                    requirements with a simple process suited to growing
                    products.
                  </p>
                </div>
                <div
                  style={{ height: 4 }}
                  className="bg-purplish w-full rounded "
                ></div>
                <div className="w-full flex justify-center items-center">
                  <div className=" w-14 h-14 rounded-full">
                    <img
                      loading="lazy"
                      className="w-14 h-14 object-contain"
                      src={imageConst.clientPic}
                      alt="founder-logo"
                    />
                  </div>
                  <div className=" ml-8 ">
                    <p className="text-white text-base font-bold">
                      Ankur Singh,
                    </p>
                    <p className="text-white text-base font-normal">
                      Founder & CEO,
                    </p>
                    <p className="text-white text-base font-normal">
                      Deltahub Capital
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
export default PricingTestimonials
