import React, { useState } from 'react'
import * as ImageConst from '../../images'
import { links } from '../../data/links'
import HeaderModal from './HeaderModal'

const HeaderComponent = () => {
  const [modal, setModal] = useState(false)
  const url = window.location.pathname.split('/')[1]
  const stickyData = [
    {
      id: 1,
      name: 'twitter',
      image: ImageConst.twitterSticky,
      link: links.TWITTER_LINK,
    },
    {
      id: 2,
      name: 'telegram',
      image: ImageConst.telegramSticky,
      link: links.TELEGRAM_LINK,
    },
    {
      id: 3,
      name: 'medium',
      image: ImageConst.mediumSticky,
      link: links.MEDIUM_LINK,
    },
  ]
  const linkData = [
    {
      name: 'DaaS',
      link: links.DAAS,
      target: false,
    },
    {
      name: 'MaaS',
      link: links.MAAS,
      target: false,
    },
    // {
    //   name: 'Pricing',
    //   link: links.PRICING,
    //   target: false,
    // },
    {
      name: 'Resources & Docs',
      link: links.GITBOOK,
      target: true,
    },
    {
      name: 'Partners',
      link: links.PARTNERS,
      target: false,
    },
  ]
  return (
    <>
      <div
        style={{
          height: 122,
          backdropFilter: 'blur(6px)',
        }}
        className=" m-auto hidden w-full md:flex justify-center items-center"
      >
        <div className="w-full max-w-1123 px-5 h-10 flex justify-between items-center">
          <a href="/">
            <div className=" w-20 h-6">
              <img
                loading="lazy"
                className="w-full h-full object-contain"
                src={ImageConst.headerLogo}
                alt="propel-logo"
              />
            </div>
          </a>
          <div className="flex justify-between items-center">
            {linkData?.map((data, index) => {
              return (
                <a
                  rel="noopener noreferrer"
                  key={data.name}
                  href={data.link}
                  className={` no-underline ${index === 0 ? '' : 'ml-9'}  ${
                    data.name.toLowerCase() === url
                      ? 'text-purplish'
                      : 'text-white'
                  } font-Mulish relative font-normal text-15 `}
                  target={`${data.target ? '_blank' : '_self'}`}
                >
                  {data.name}
                  {data.name.toLowerCase() === url && (
                    <div className="absolute -left-3 h-1 bg-purplish -right-3 -bottom-4"></div>
                  )}
                </a>
              )
            })}
          </div>
          <div className="flex gap-3 justify-center items-center">
            <a href={links.LAUNCH_APP} target="_blank" rel="noreferrer">
              <button className=" w-28 h-10 rounded-sm flex justify-center items-center text-white bg-purplish font-Mulish font-bold text-sm">
                Launch App
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className=" md:hidden">
        <div
          style={{
            backdropFilter: 'blur(6px)',
          }}
          className=" m-auto flex w-full justify-center mb-6 items-center h-20"
        >
          <div className="w-full max-w-1123 px-5 h-10 flex justify-between items-center">
            <a href="/">
              <div className=" w-20 h-6">
                <img
                  loading="lazy"
                  className="w-full h-full object-contain"
                  src={ImageConst.headerLogo}
                  alt="propel-logo"
                />
              </div>
            </a>
            <div className="flex justify-center items-center">
              <div className="h-6 mr-6 flex justify-center items-center">
                {stickyData.map((data, index) => {
                  return (
                    <a
                      style={{
                        background: '#E0E0E0',
                      }}
                      rel="noreferrer"
                      href={data.link}
                      target="_blank"
                      key={index}
                      className={`w-6 h-6 rounded-full flex justify-center items-center ${
                        index !== 0 ? 'ml-2' : ''
                      }`}
                    >
                      <img src={data.image} alt={data.name} />
                    </a>
                  )
                })}
              </div>
              <div className=" cursor-pointer" onClick={() => setModal(!modal)}>
                <div
                  style={{
                    width: 18,
                    height: 2,
                  }}
                  className="bg-white rounded-md"
                ></div>
                <div
                  style={{
                    width: 18,
                    height: 2,
                    margin: '5px 0',
                  }}
                  className="bg-white rounded-md"
                ></div>
                <div
                  style={{
                    width: 18,
                    height: 2,
                  }}
                  className="bg-white rounded-md"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <HeaderModal linkData={linkData} setModal={setModal} modal={modal} />
      )}
    </>
  )
}
export default HeaderComponent
