import * as imageConst from '../../images'
import { links } from '../../data/links'

const CustomisedSolutions = () => {
  return (
    <>
      <div className=" w-full pb-24">
        <div
          style={{
            background: ' #121212',
            boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08)',
            maxWidth: 1236,
          }}
          className="flex-wrap rounded-lg w-full m-auto flex justify-between items-center"
        >
          <div className=" flex-1 py-10 pl-14 pr-5 text-center sm:text-left">
            <p className=" text-white text-xl font-bold">
              Need something customised?
            </p>
            <p
              style={{
                color: '#C1C1C1',
              }}
              className=" font-normal text-base my-6"
            >
              We'll work together to set up a customized solution at the best
              price. Let's get on call..
            </p>
            <a href={links.CALENDLY} className="m-auto sm:m-0">
              <button
                style={{
                  width: 204,
                }}
                className=" text-white m-auto sm:m-0 font-bold text-15 flex justify-center items-center bg-purplish rounded h-10"
              >
                <span className=" mr-2">
                  <img src={imageConst.headphones} alt="headphones" />
                </span>
                <p>Contact Sales</p>
              </button>
            </a>
          </div>
          <div
            style={{
              flex: '1 1 260px',
            }}
          >
            <img
              src={imageConst.customisedBg}
              className=" h-full w-full object-contain"
              alt="solutions"
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default CustomisedSolutions
