import * as imageConst from '../common/images'

const LaunchPool = () => {
  return (
    <>
      <div className="w-full min-h-screen m-auto max-w-1123 flex flex-col justify-center items-center">
        <img src={imageConst.commingSoon} alt="comming soon" />
        <a
          href="https://propel.xyz/"
          className=" text-purplish underline font-bold text-xl"
        >
          {' '}
          Go Back To Homepage{' '}
        </a>
      </div>
    </>
  )
}
export default LaunchPool
