import * as imageConst from '../../images'
import Marquee from 'react-fast-marquee'

const AvailableOn = () => {
  const AvailableImages = [
    {
      name: 'binance',
      imageLink: imageConst.binance,
    },
    {
      name: 'avalanche',
      imageLink: imageConst.avalanche,
    },
    {
      name: 'xDai',
      imageLink: imageConst.xDai,
    },
    {
      name: 'optimism',
      imageLink: imageConst.optimism,
    },
    {
      name: 'kucoin',
      imageLink: imageConst.kucoin,
    },
    {
      name: 'polkadot',
      imageLink: imageConst.polkadot,
    },
    {
      name: 'solana',
      imageLink: imageConst.solana,
    },
    {
      name: 'binance',
      imageLink: imageConst.binance,
    },
    {
      name: 'avalanche',
      imageLink: imageConst.avalanche,
    },
    {
      name: 'xDai',
      imageLink: imageConst.xDai,
    },
    {
      name: 'optimism',
      imageLink: imageConst.optimism,
    },
    {
      name: 'kucoin',
      imageLink: imageConst.kucoin,
    },
    {
      name: 'polkadot',
      imageLink: imageConst.polkadot,
    },
    {
      name: 'solana',
      imageLink: imageConst.solana,
    },
  ]
  return (
    <>
      <div className="w-full -mt-3 hidden sm:block">
        <div className="relative w-full py-3 m-auto overflow-x-hidden">
          <div
            style={{
              backgroundImage: 'radial-gradient(#2A2D2F 15%, #343739 80%)',
              height: 1.23,
            }}
            className="w-full absolute top-3 right-0 left-0"
          ></div>
          <div
            style={{
              backgroundImage: 'radial-gradient(#2A2D2F 15%, #343739 80%)',
              height: 1.23,
            }}
            className="w-full absolute bottom-0 right-0 left-0"
          ></div>
          <div className="w-full m-auto flex justify-start items-start flex-nowrap">
            <Marquee
              speed={40}
              direction={'left'}
              pauseOnHover={true}
              gradient={false}
            >
              {AvailableImages.map((name, index) => (
                <div
                  className=" h-59 mt-5 mr-5 flex justify-center items-center rounded-3xl"
                  key={index}
                >
                  <img loading="lazy" src={name.imageLink} alt={name.name} />
                  <p></p>
                </div>
              ))}
            </Marquee>
          </div>
        </div>
      </div>
    </>
  )
}
export default AvailableOn
